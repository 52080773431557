<template>
    <div class="p-relative">

        <Toolbar :isList="true" :menu="fakeMenu" v-on:toolbar-button-click="onToolbarClick" />
        
        <v-form class="wrapperForm">
            <v-row dense>

                <!--Обертка для секции-->
                <v-col cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title"> {{ $t("Корреспонденция") }} </div>

                            <v-list 
                                class="striped-list-card"
                                flat
                                dense
                            >
                                <v-list-item
                                    v-for="(item, i) in correspondenceReports"
                                    :key="i"
                                    :value="item"
                                    @click="reportClick(item)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ $t(item.text) }}
                                        </v-list-item-title>
                                    </v-list-item-content>

                                </v-list-item>
                            </v-list>

                        </v-card-text>
                    </v-card>
                </v-col>

                <!--Обертка для секции-->
                <v-col cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title"> {{ $t("Обращения") }} </div>

                            <v-list 
                            class="striped-list-card"
                            flat
                            dense
                            >
                                <v-list-item
                                    v-for="(item, i) in euolReports"
                                    :key="i"
                                    :value="item"
                                    @click="reportClick(item)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ $t(item.text) }}
                                        </v-list-item-title>
                                    </v-list-item-content>

                                </v-list-item>
                            </v-list>

                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>
        </v-form>
        <ReportPeriodDlg ref="ReportPeriodDlgRef" />
    </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import ReportPeriodDlg from '@/components/dialogs/ReportPeriodDlg';
//import { httpAPI, errorHandler } from "@/api/httpAPI";

export default {
    name: "Reports",
    components:{
        Toolbar,
        ReportPeriodDlg
    },
    computed: {
        fakeMenu() {
            return [];
        },
        correspondenceReports() {
            return [
                { type: 'Documents.C2.LanguageNew', text: 'Отчет_по_документообороту_на_гос_языке', param: 'period-param' },
                { type: 'Documents.C2.IncomingDocumentsNew', text: 'Отчет_о_входящей_корреспонденции', param: 'year-param' },
                { type: 'Documents.C2.OutgoingDocumentsNew', text: 'Отчет_об_исходящей_корреспонденции', param: 'year-param' },
                { type: 'Chancellery.C2.UnregRejectedDocs', text: 'Сведения_по_отклоненным_документам', param: 'period-param' },
                { type: 'Documents.C2.ControlDocs', text: 'Отчет_по_контрольным_документам', param: 'period-param' },
                { type: 'Documents.C2.DocflowWorkingInfo', text: 'Отчет_о_количестве_документов_в_служебной_корреспонденции', param: 'period-param' },
                { type: 'Documents.C2.DocflowExecutingIncoming', text: 'Отчет_по_исполнению_входящей_корреспонденции', param: 'period-param' },
                { type: 'Documents.C2.CGO_MIO', text: 'Отчет_об_использовании_электронного_документооборота_ЦГО_и_МИО', param: 'period-param' },
                { type: 'Documents.C2.ActExecution_AP_KPM', text: 'Отчет-напоминание_об_исполнении_поручений_АП_РК_и_КПМ_РК', param: 'period-param'},
                { type: 'Documents.C2.PerformingDiscip', text: 'Анализ_исполнительской_дисциплины', param: 'period-param'},
                { type: 'Documents.C2.RemindingControlDocs', text: 'Отчет_напоминание_по_контрольным_документам', param: 'date-param'}
            ];
        },
        euolReports() {
            return [
                { type: 'Documents.C2.Euol_1_OL', text: 'Отчет_формы_1-ОЛ', param: 'period-param' },
                { type: 'Documents.C2.EuolByDecision', text: 'Информация_о_количестве_письменных_обращений', param: 'period-param' },
                { type: 'Documents.C2.EuolStatementExecution', text: 'Сведения_об_исполнении_обращений', param: 'date-param' },
                { type: 'Documents.C2.ActOfReconciliation', text: 'Акт_сверки', param: 'period-param' },
            ];
        }
    },
    data: () => ({
        //urls: []
    }),
    methods: {
        onToolbarClick() {

        },
        async reportClick(reportItem) {
            try
            {
                let report = {};
                let url = '';

                if (reportItem.type == 'Documents.C2.RemindingControlDocs') {
                    report = {type:'Xls'};
                    url = `api/download/formatedreport?type=${reportItem.type}&format=${report.type}&mode=${process.env.NODE_ENV}`;
                }
                else {
                    report = await this.$refs.ReportPeriodDlgRef.open(reportItem);
                    url = `api/download/formatedreport?type=${reportItem.type}&format=${report.type}&param=${JSON.stringify(report.param)}&mode=${process.env.NODE_ENV}`;
                }

                if (process.env.NODE_ENV === "production")
                    url += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;

                this.$eventBus.$emit('on-get-report', {url, text: reportItem.text, type: report.type });


                /*let reportRequst = httpAPI({ url: url, method: 'GET', responseType: 'blob', skipErrorHandler: true });
                // при успешном выполнении запроса на формирование отчёта выполнить:
                var onResolve = (response) => {
                    this.$notify.success(`${this.$t(reportItem.text)}`);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    this.urls.push(url);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', report.type === 'PDF' ? `${this.$t(reportItem.text)}.pdf` : `${this.$t(reportItem.text)}.xls` );
                    document.body.appendChild(link);
                    link.click();
                };
                // при неудачном выполнении запроса на формирование отчёта выполнить:
                var onReject = (error) => {
                    errorHandler(error);
                };
                let asyncReportToastMessage = `${ this.$t(reportItem.text) }`;
                this.$notify.async(reportRequst, onResolve, onReject, asyncReportToastMessage, { icons: { enabled: true } });*/
            }
            catch (ex)
            {
                console.log(ex);
            }
        }
    },
    beforeDestroy() {
        //this.urls.forEach(url => URL.revokeObjectURL(url));
    }
}
</script>